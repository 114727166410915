import type { Database } from '@/types/database.types'

export default defineNuxtRouteMiddleware(async (to) => {
  const user = useSupabaseUser()
  const supabase = useSupabaseClient<Database>()

  if (!user.value) {
    const cookies = useCookie('redirect')
    cookies.value = to.path
    return navigateTo({ name: 'login' })
  }

  // TODO: typing
  const { data: profile, error } = await supabase
    .from('profiles')
    .select('*')
    .eq('id', user.value?.id)
    .limit(1)
    .single()

  if (error) {
    console.error(error)
    return navigateTo({ name: 'login' })
  }

  if (
    to.name !== 'plattform-registrieren' &&
    (!profile.first_name || !profile.last_name || !profile.company_id)
  ) {
    return navigateTo({ name: 'plattform-registrieren' })
  }
})
